@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
*:focus {
	border-color: inherit !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}
.pointer {
	cursor: pointer;
}
.text__yellow {
	color: #fcbd1b;
}
.bg__yellow {
	color: #fcbd1b;
}
.text__orange {
	color: #fb7800;
}
.bg__orange {
	color: #fb7800;
}
.text__gray {
	color: #4a4a4a;
}
.bg__gray {
	color: #4a4a4a;
}
.fw-800 {
	font-weight: 800 !important;
}
.fw-700 {
	font-weight: 700 !important;
}
.fw-600 {
	font-weight: 600 !important;
}
.fw-500 {
	font-weight: 500 !important;
}
.fw-400 {
	font-weight: 400 !important;
}
.fw-300 {
	font-weight: 300 !important;
}
.fs-64 {
	font-size: 64px !important;
}
.fs-58 {
	font-size: 58px !important;
}
.fs-48 {
	font-size: 48px !important;
}
.fs-36 {
	font-size: 36px !important;
}
.fs-32 {
	font-size: 32px !important;
}
.fs-24 {
	font-size: 24px !important;
}
.fs-20 {
	font-size: 20px !important;
}
.fs-18 {
	font-size: 18px !important;
}
.fs-16 {
	font-size: 16px !important;
}
.fs-15 {
	font-size: 15px !important;
}
.css-df17o1 {
	position: fixed !important;
}
.css-42igfv {
	text-align: -webkit-center;
}

.custom__nav {
	height: 96px;
}
.brand {
	font-family: 'Roboto' !important;
	font-style: normal !important;
	font-weight: 700 !important;
	font-size: 36px !important;
	line-height: 42px !important;
}
.btn__round {
	padding: 15px !important;
	border: 0 !important;
	background: #fb7800 !important;
	border-radius: 40px !important;
	font-family: 'Roboto' !important;
	font-style: normal !important;
	font-weight: 700 !important;
	font-size: 15px !important;
	line-height: 18px !important;
	/* identical to box height */

	text-align: center !important;
	letter-spacing: 0.01em !important;
	text-transform: uppercase !important;

	/* White */

	color: #ffffff !important;
}
.header__container {
	background: #fcbd1b !important;
}
.header__container .nav-link {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400 !important;
	font-size: 16px !important;
	line-height: 19px !important;
	color: white !important;
}
.custom__nav .nav-link {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400 !important;
	font-size: 16px !important;
	line-height: 19px !important;
	color: white !important;
}
.hero__container {
	background: url('./assets/img/header_bg.png');
	background-repeat: no-repeat;
	box-shadow: inset 0 0 0 1000px rgba(248, 247, 247, 0.7);
}
.hero__heading {
	font-family: 'Roboto';
	font-style: normal;
	font-size: 48px !important;
	line-height: 150% !important;
}
.hero__desc {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400 !important;
	font-size: 16px !important;
	line-height: 150% !important;
}
.section__heading {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 140%;
	/* or 25px */
	color: #000000;
}
.underline__hr {
	width: 3% !important;
	margin-top: 2px !important;
	background: #fcbd1b !important;
	opacity: 1;
	height: 3px !important;
}
.withdraw__card {
	background: #4a4a4a !important;
	border-radius: 20px !important;
}
.withdraw__label {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 18px;
	/* identical to box height */
	text-transform: capitalize;
	color: #ffffff;
}
.withdraw__number {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-size: 48px;
	line-height: 56px;
	color: #ffffff;
}
.avax__price__card {
	background: #fb7800 !important;
	border-radius: 20px !important;
}
.card-header {
	background: #fcbd1b !important;
	border: 0 !important;
	border-radius: 20px 20px 0px 0px !important;
	height: 137px !important;
	padding: 15px 30px !important;
}
.custom__parent__round {
	background: #ffffff !important;
	border-radius: 60px !important;
	height: 124px !important;
	width: 80%;
	border: 0 !important;
	margin-top: -15%;
}
.custom__child__round {
	height: 114px !important;
	width: 100%;
	background: #ffffff !important;
	border: 2px solid #fcbd1b !important;
	box-sizing: border-box !important;
	border-radius: 60px !important;
}
.package__card__body {
	padding: 15px 30px !important;
}
.package__card {
	background: #f8f7f7 !important;
	border-radius: 20px !important;
	border: 0 !important;
}
.package__card-disabled {
	background: #f8f7f7 !important;
	border-radius: 20px !important;
	border: 0 !important;
	opacity: 0.4;
}
.plan__label {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 140%;
	/* identical to box height, or 28px */
	color: #ffffff;
}
.custom__input {
	background: #ffffff !important;
	border-radius: 10px !important;
	height: 54px !important;
	border: 0 !important;
}
.days__number {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 58px;
	line-height: 68px;
	text-align: center;
	font-feature-settings: 'pnum' on, 'lnum' on;

	color: #fcbd1b;
}
.days {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	letter-spacing: 0.01em;

	color: #fcbd1b;
}
.days__desc {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 130%;
	/* or 19px */

	text-align: center;

	color: #000000;
}
.referral__card {
	background: #f8f7f7 !important;
	border-radius: 20px !important;
	border: 0 !important;
}
.referral__input {
	width: 50% !important;
}
.level_number {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 600;
	font-size: 64px;
	line-height: 75px;
	text-transform: capitalize;

	color: #222222;
}

span.countdown {
	font-size: 18px;
}
.level_1 {
	position: absolute;
	left: 28%;
	top: 18%;
}
.level_2 {
	position: absolute;
	left: 47%;
	top: 18%;
}
.level_3 {
	position: absolute;
	left: 67%;
	top: 18%;
}

span.fs-24.mt-0.fw-800 {
	color: #fcbd1b;
}
.custom-content {
	background: url('./assets/img/Lauch_bg.png') !important;
	background-size: cover !important;
	border-radius: 0.5rem !important;
}

.launch_in {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	font-size: 64px;
	line-height: 90px;
	text-align: center;
	text-transform: uppercase;
	color: #ffffff;
}
.timer-label {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	font-size: 20px;
	line-height: 25px;
	text-align: center;

	color: #ffffff;
}
.timer-time {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	font-size: 50px;
	line-height: 100px;
	/* identical to box height */
	text-align: center;
	color: #ffffff;
}
.timer-time-1 {
	position: absolute;
	left: 9%;
	top: 54%;
}
.timer-time-2 {
	position: absolute;
	left: 35.4%;
	top: 54%;
}
.timer-time-3 {
	position: absolute;
	left: 60.6%;
	top: 54%;
}
.timer-time-4 {
	position: absolute;
	left: 87%;
	top: 54%;
}
.timer-circle {
	height: 150px;
}

.navbar-collapse {
    z-index: 2;
}
@media (max-width: 1399.98px) {
	.level_1 {
		position: absolute;
		left: 24%;
		top: 18%;
	}
	.level_2 {
		position: absolute;
		left: 47%;
		top: 18%;
	}
	.level_3 {
		position: absolute;
		left: 70%;
		top: 18%;
	}
	.timer-time-1 {
		left: 10.5%;
	}
	.timer-time-2 {
		left: 35.4%;
	}
	.timer-time-3 {
		left: 60%;
	}
	.timer-time-4 {
		left: 85%;
	}
}
@media (max-width: 1199.98px) {
	.level_1 {
		position: absolute;
		left: 19%;
		top: 18%;
	}
	.level_2 {
		position: absolute;
		left: 46%;
		top: 18%;
	}
	.level_3 {
		position: absolute;
		left: 73%;
		top: 18%;
	}
	.timer-time-1 {
		left: 12.6%;
	}
	.timer-time-2 {
		left: 36%;
	}
	.timer-time-3 {
		left: 58.5%;
	}
	.timer-time-4 {
		left: 81.5%;
	}
}
@media (max-width: 991.98px) {
	.level_1 {
		position: absolute;
		left: 12%;
		top: 16%;
	}
	.level_2 {
		position: absolute;
		left: 45%;
		top: 16%;
	}
	.level_3 {
		position: absolute;
		left: 77%;
		top: 16%;
	}
	.launch_in {
		font-size: 44px;
		line-height: 70px;
	}
	.timer-label {
		font-size: 16px;
		line-height: 25px;
	}
	.timer-time {
		font-size: 30px;
		line-height: 100px;
	}
	.timer-time-1 {
		left: 10%;
		top: 52%;
	}
	.timer-time-2 {
		left: 35.5%;
		top: 52%;
	}
	.timer-time-3 {
		left: 60.5%;
		top: 52%;
	}
	.timer-time-4 {
		left: 85.5%;
		top: 52%;
	}
	.timer-circle {
		height: 110px;
	}
}
@media (max-width: 767.98px) {
	.top_nav {
		max-width: 100% !important;
		padding: 0 !important;
	}
	.custom__nav .container-fluid {
		padding: 0 !important;
	}
	.custom__collapse {
		background: #fcbd1b !important;
		padding: 20px !important;
		height: 100vh;
		margin-top: -8px;
	}
	.underline__hr {
		width: 10% !important;
	}
	.referral__input {
		width: 100% !important;
	}
	.level_number {
		font-size: 50px !important;
	}
	.level_1 {
		position: absolute;
		left: 44%;
		top: 6%;
	}
	.level_2 {
		position: absolute;
		left: 44%;
		top: 25%;
	}
	.level_3 {
		position: absolute;
		left: 44%;
		top: 45%;
	}
	.fs-32 {
		font-size: 24px !important;
	}
	.timer-time-1 {
		left: 13%;
	}
	.timer-time-2 {
		left: 36%;
	}
	.timer-time-3 {
		left: 58%;
	}
	.timer-time-4 {
		left: 81%;
	}
}
@media (max-width: 490px) {
	.launch_in {
		font-size: 44px;
		line-height: 70px;
	}
	.timer-label {
		font-size: 15px;
		line-height: 25px;
	}
	.timer-time {
		font-size: 30px;
		line-height: 100px;
	}
	.timer-time-1 {
		left: 12%;
	}
	.timer-time-2 {
		left: 35%;
	}
	.timer-time-3 {
		left: 58%;
	}
	.timer-time-4 {
		left: 81%;
	}
	.timer-circle {
		height: 90px;
	}
}
@media (max-width: 440px) {
	.timer-time-1 {
		left: 14%;
	}
	.timer-time-2 {
		left: 35.5%;
	}
	.timer-time-3 {
		left: 57%;
	}
	.timer-time-4 {
		left: 78%;
	}
}
@media (max-width: 425px) {
	.brand {
		font-size: 25px !important;
	}
	.hero__heading {
		line-height: 50px !important;
	}
	.level_1 {
		position: absolute;
		left: 42%;
		top: 6%;
	}
	.level_2 {
		position: absolute;
		left: 42%;
		top: 25%;
	}
	.level_3 {
		position: absolute;
		left: 42%;
		top: 45%;
	}
}
@media (max-width: 385px) {
	.launch_in {
		font-size: 38px;
		line-height: 50px;
	}
	.timer-label {
		font-size: 15px;
		line-height: 25px;
	}
	.timer-time {
		font-size: 25px;
		line-height: 100px;
	}
	.timer-time-1 {
		left: 14%;
		top: 49%;
	}
	.timer-time-2 {
		left: 36%;
		top: 49%;
	}
	.timer-time-3 {
		left: 57.5%;
		top: 49%;
	}
	.timer-time-4 {
		left: 78.5%;
		top: 49%;
	}
	.timer-circle {
		height: 70px;
	}
}
@media (max-width: 370px) {
	.fs-64 {
		font-size: 58px !important;
	}
}
@media (max-width: 335px) {
	.timer-time-1 {
		left: 15%;
		top: 49%;
	}
	.timer-time-2 {
		left: 37%;
		top: 49%;
	}
	.timer-time-3 {
		left: 58%;
		top: 49%;
	}
	.timer-time-4 {
		left: 80%;
		top: 49%;
	}
	.timer-circle {
		height: 70px;
	}
}
